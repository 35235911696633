import React from 'react';

import BpkBreakpoint, {
  BREAKPOINTS,
} from '@skyscanner/backpack-web/bpk-component-breakpoint';

import type { UserContext } from 'common/src/types/user-context';

import { isBrowser } from '../../../utils/isBrowser';
import { withUserContext } from '../../UserContext';

type Props = {
  query: string;
  children: Function;
  userContext: UserContext;
};

/* BpkBreakpointWrapper
 * BpkBreakpoint cannot support server side rendering,
 * and if only use device to detect that cannot support responsive layout
 *
 * This wrapper allows compatible server side rendering
 */

const BpkBreakpointWrapper = ({ children, query, userContext }: Props) => {
  if (isBrowser()) {
    return (
      <BpkBreakpoint query={query}>
        {(isActive: boolean) => children(isActive)}
      </BpkBreakpoint>
    );
  }

  let isActive = false;
  if (
    (query === BREAKPOINTS.MOBILE && userContext.device.isMobilePhone) ||
    (query === BREAKPOINTS.TABLET && userContext.device.isTablet) ||
    (query === BREAKPOINTS.ABOVE_MOBILE && !userContext.device.isMobilePhone) ||
    (query === BREAKPOINTS.ABOVE_TABLET && userContext.device.isBrowser)
  ) {
    isActive = true;
  }

  return <React.Fragment>{children(isActive)}</React.Fragment>;
};

export default withUserContext(BpkBreakpointWrapper);
