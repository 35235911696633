import React from 'react';
import type { MouseEvent } from 'react';

import RouteLink from './RouteLink';

import type { RouterRenderFunction, DomEvent } from './RouteLink';

type Props = {
  href?: string;
  onClick: DomEvent;
  render: RouterRenderFunction;
};

const ConditionRouterLink = ({ href, onClick, render }: Props) => {
  if (href) {
    return <RouteLink href={href} onClick={onClick} render={render} />;
  }
  return render(onClick);
};

export default ConditionRouterLink;
