import type { ReactElement } from 'react';

import get from 'lodash/get';
import { matchPath, withRouter } from 'react-router-dom';

import type { ModalClose } from '../types';
import type { History, Location } from 'history';
// @ts-expect-error TS2305: Module '"react-router-dom"' has no exported member 'StaticContext'.
import type { RouteComponentProps, StaticContext } from 'react-router-dom';

type RenderProps = {
  match: any;
  history: History;
  location: Location;
  onClose: ModalClose;
};

type Props = {
  render: (params: RenderProps) => ReactElement;
} & RouteComponentProps<{ [x: string]: string | undefined }, StaticContext>;

type RouteLinkProps = Props & {
  hash: string;
};

const HashRoute = ({ hash, history, location, render }: RouteLinkProps) => {
  const match = matchPath(location.hash, hash);
  const onClose = () => {
    const shouldGoBack = get(window, 'history.state.state.shouldGoBack');
    if (shouldGoBack) {
      history.goBack();
    } else {
      // Why we use window.location.search is for the case like:
      // On the mWeb dayview page, when filter is selected
      // It will change the url by updateSearchParamsUrl using window.history.pushState
      // See: packages/client/src/services/url/url.js
      // Then the pushHashByRouteLink is lost
      // And the location from react-router-dom is not match the window.location
      // We use the latest window.location.search
      const { search } = window.location;
      history.push({ ...location, search, hash: undefined });
    }
  };
  if (match && render) {
    return render({ history, location, match, onClose });
  }
  return null;
};

export default withRouter(HashRoute);
export type { RenderProps };
