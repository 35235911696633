import React from 'react';
import type { ComponentType } from 'react';

import displayName from '../display-name';

import HashRoute from './HashRoute';

import type { RenderProps } from './HashRoute';

interface BasicProps {
  hash?: string;
  isOpen?: boolean;
  onClose: Function;
}

const withConditionHashRoute = <P extends object>(
  Wrapper: ComponentType<P>,
) => {
  const Component = ({ hash, isOpen, onClose, ...rest }: BasicProps & P) => {
    if (hash) {
      return (
        <HashRoute
          hash={hash}
          render={(routeProps: RenderProps) => (
            <Wrapper
              {...(rest as P)}
              isOpen
              onClose={() => {
                onClose(routeProps.onClose);
              }}
            />
          )}
        />
      );
    }
    return <Wrapper {...(rest as P)} isOpen={isOpen} onClose={onClose} />;
  };

  Component.displayName = displayName(Wrapper);

  return Component;
};

export default withConditionHashRoute;
