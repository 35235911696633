import React from 'react';
import type { ComponentType } from 'react';

import displayName from '../../display-name';

import BpkBreakpointWrapper from './BpkBreakpointWrapper';

const withBpkBreakpoint = <P extends object>(
  WrappedComponent: ComponentType<P>,
  query: string,
  name: string = 'isActive',
) => {
  const component = ({ ...props }: P) => (
    <BpkBreakpointWrapper query={query}>
      {(isActive: boolean) => {
        const nameProps = { [name]: isActive };
        return <WrappedComponent {...props} {...nameProps} />;
      }}
    </BpkBreakpointWrapper>
  );

  component.displayName = displayName(WrappedComponent);

  return component;
};
export default withBpkBreakpoint;
