import React from 'react';
import type { ComponentType } from 'react';

import displayName from '../display-name';

import RouteLink from './RouteLink';

type Props = {
  onClick?: Function;
  [key: string]: any;
};

const withRouterLink = <P extends Props>(
  WrappedComponent: ComponentType<P>,
  href: string,
) => {
  const component = (props: P) => (
    <RouteLink
      href={href}
      onClick={props.onClick}
      render={(event) => <WrappedComponent {...props} onClick={event} />}
    />
  );
  component.defaultProps = {
    onClick: null,
  };

  component.displayName = displayName(WrappedComponent);

  return component;
};

export default withRouterLink;
