import React from 'react';
import type { ReactElement, ComponentType } from 'react';

import displayName from '../display-name';
import withScrimmedPortal from '../Common/withScrimmedPortal';

import HashRoute from './HashRoute';

import type { RenderProps } from './HashRoute';

interface BasicProps {
  hash?: string;
  isOpen?: boolean;
  closeModal?: Function;
}

interface ChildrenProps extends BasicProps {
  children: ReactElement;
  render?: undefined;
}

interface Props extends BasicProps {
  render: Function;
  children?: undefined;
}

const withHashRoutePortal = <P extends object>(Wrapper: ComponentType<P>) => {
  const ScrimmedPortal = withScrimmedPortal(Wrapper);
  const ScrimmedRouter = ({
    children: propsChildren,
    closeModal,
    hash,
    isOpen,
    render,
    ...rest
  }: (ChildrenProps | Props) & Omit<P, 'onClose'>) => {
    if (hash) {
      return (
        <HashRoute
          hash={hash}
          render={(routeProps: RenderProps) => {
            const { onClose } = routeProps;
            const children = render ? render(routeProps) : propsChildren;
            return (
              <ScrimmedPortal
                {...rest}
                isOpen
                neverCloseFromPortal
                onClose={onClose}
              >
                {children}
              </ScrimmedPortal>
            );
          }}
        />
      );
    }
    const children = render ? render({ onClose: closeModal }) : propsChildren;
    return (
      <ScrimmedPortal
        {...rest}
        isOpen={!!isOpen}
        onClose={closeModal}
        neverCloseFromPortal
      >
        {children}
      </ScrimmedPortal>
    );
  };

  ScrimmedRouter.displayName = displayName(Wrapper);

  return ScrimmedRouter;
};

export default withHashRoutePortal;
