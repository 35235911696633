import React from 'react';
import type { ReactElement, ReactNode } from 'react';

import BpkLoadingButtonComponent from '@skyscanner/backpack-web/bpk-component-loading-button';

import type { Maybe } from 'common/src/types/utils';
import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../skyscanner-application/i18n';

type Props = {
  i18n: I18nService;
  children?: ReactElement | string;
  className?: string;
  large?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  icon?: ReactElement;
  link?: boolean;
  loading?: boolean;
  type?: string;
  onClick?: Maybe<Function>;
  href?: string;
  featured?: boolean;
  iconDisabled?: ReactNode;
};

/* BpkLoadingButton
 * Backpack loading button will cause the client crash when user using google translation.
 *
 * This wrapper will avoid the client crash.
 */

const BpkLoadingButton = ({ children, i18n, ...rest }: Props) => {
  if (!children) {
    return (
      <BpkLoadingButtonComponent {...rest}>
        <span className="visually-hidden">
          {i18n.translate('hdb_search_filters_button_loading')}
        </span>
      </BpkLoadingButtonComponent>
    );
  }

  return (
    <BpkLoadingButtonComponent {...rest}>
      <span>{children}</span>
    </BpkLoadingButtonComponent>
  );
};

export default withI18n(BpkLoadingButton);
