import type { ComponentType } from 'react';
import React from 'react';

import displayName from '../../display-name';

import STYLES from './StackingContextReset.scss';

const withStackingContextReset = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  const component = (props: P) => (
    <div className={STYLES.StackingContextReset}>
      <WrappedComponent {...props} />
    </div>
  );

  component.displayName = displayName(WrappedComponent);

  return component;
};

export default withStackingContextReset;
